<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Exámenes Médicos</span>
            <v-spacer></v-spacer>
            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="(dialog_agregar = true), getUsuarios()"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>
            <v-btn
              color="green"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" lg="5">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  dense
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="examenes"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  hide-default-footer
                  dense
                >
                  <template v-slot:item.examen="{ item }">
                    <span>
                      <v-icon
                        color="primary"
                        @click="(idfoto = item), (dialogImagen = true)"
                      >
                        mdi-file-image
                      </v-icon>
                    </span>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      color="primary"
                      x-small
                      @click="editItem(item)"
                      class="mr-2"
                    >
                      <v-icon small>mdi-magnify</v-icon>
                    </v-btn>                  
                  </template>

                  <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize" small>
                      Actualizar
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogo de agregar-->
    <v-dialog v-model="dialog_agregar" max-width="400px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <label style="color: #333333">Formulario</label>
          <v-autocomplete
            :items="formularios.formularios"
            v-model="defaultItem.idformulario"
            outlined
            placeholder="Ej. Angel Rodriguez"
            dense
            item-text="nombre_completo"
            item-value="idformulario"
          ></v-autocomplete>

          <label style="color: #333333">Comentarios</label>
          <v-text-field
            v-model="defaultItem.comentario"
            single-line
            dense
            placeholder="Los comentarios del examen médico son..."
          ></v-text-field>

          <v-file-input
            v-model="addcvfiles"
            accept="image/png, image/jpeg, image/bmp, application/pdf"
            placeholder="Selecciona el examen"
            label="Subir Examen"
            multiple
            prepend-icon="mdi-paperclip"
            @change="cargarFotosCV()"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                small
                label
                color="primary"
                close
                @click:close="eliminarfile(index, text)"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>

          <!-- Mostrar la imagen del examen-->
          <v-row>
            <v-col
              cols="12"
              md="11"
              v-for="(img, i) in vistaPreviasCV"
              :key="i"
            >
              <v-card
                class="py-4 shadowCard"
                v-if="img.extensioArchivo != 'pdf'"
              >
                <v-img :src="img.url" contain aspect-ratio="2"></v-img>
                <v-btn
                  color="error"
                  small
                  @click="eliminarFoto(img.url)"
                  top
                  right
                  absolute
                  fab
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card>
              <v-card
                class="py-4 shadowCard"
                v-if="img.extensioArchivo == 'pdf'"
              >
                <embed :src="img.url" type="application/pdf" />
                <v-btn
                  color="error"
                  small
                  @click="eliminarFoto(img.url)"
                  top
                  right
                  absolute
                  fab
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
          <v-btn color="blue darken-1" class="elevation-6" dark @click="save">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo de editar-->
    <v-dialog v-model="dialog_editar" max-width="400px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle2 }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
            <strong>ID: {{ editedItem.idexamenes_medicos }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <label style="color: #333333">Nombre Completo</label>
          <v-autocomplete
            :items="formularios.formularios"
            v-model="editedItem.idformulario"
            outlined
            placeholder="Ej. Angel Rodriguez"
            dense
            item-text="nombre_completo"
            item-value="idformulario"
          ></v-autocomplete>

          <label style="color: #333333">Comentarios</label>
          <v-text-field
            v-model="editedItem.comentario"
            single-line
            dense
            placeholder="Ej. Los comentarios del examen médico son... ."
          ></v-text-field>

          <!-- Linea/ROW que muestra la imagen para poder previsualizarla antes de querar actualizarla-->
          <v-row>
            <v-col
              cols="11"
              md="11"
              lg="11"
      
              v-if="editedItem.examen != null && editedItem.examen != ''"
            >
              <v-card>
                <!--Titular-->
                <v-card-text class="py-2" style="color: black">
                  Examen
                </v-card-text>
                <div style="display: none">
                  <v-text-field v-model="editedItem.examen"></v-text-field>
                  <!-- En caso de que no se actualize la imagen o pdf, tomamos el nombre de aqui, esta oculto-->
                </div>

                <!-- 1.-En caso de que sea imagen-->
                <template
                  v-if="
                    editedItem.examen.endsWith('.jpg') ||
                    editedItem.examen.endsWith('.jpeg') ||
                    editedItem.examen.endsWith('.png') ||
                    editedItem.examen.endsWith('.gif')
                  "
                >
                  <img
                    :src="url_servidor + editedItem.examen"
                    alt="imagen"
                    style="max-width: 300px; max-height: 300px"
                  />
                </template>
                <!-- 2.-En caso de que sea pdf-->
                <template v-else-if="editedItem.examen.endsWith('.pdf')">
                  <embed
                    :src="url_servidor + editedItem.examen"
                    type="application/pdf"
                  />
                </template>
              </v-card>
            </v-col>
          </v-row>

          <!--Campo donde se introduce la foto o pdf-->
          <v-file-input
            v-model="addcvfiles"
            accept="image/png, image/jpeg, image/bmp, application/pdf"
            placeholder="Selecciona el examen"
            label="Actualizar Examen"
            multiple
            prepend-icon="mdi-paperclip"
            @change="cargarFotosCV()"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                small
                label
                color="primary"
                close
                @click:close="eliminarfile(index, text)"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>

          <!--Linea/ROW que muestra cual sera la nueva imagen o pdf-->
          <v-row>
            <v-col
              cols="12"
              md="11"
              v-for="(img, i) in vistaPreviasCV"
              :key="i"
            >
              <v-card
                class="py-4 shadowCard"
                v-if="img.extensioArchivo != 'pdf'"
              >
                <v-img :src="img.url" contain aspect-ratio="2"></v-img>
                <v-btn
                  color="error"
                  small
                  @click="eliminarFoto(img.url)"
                  top
                  right
                  absolute
                  fab
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card>
              <v-card
                class="py-4 shadowCard"
                v-if="img.extensioArchivo == 'pdf'"
              >
                <embed :src="img.url" type="application/pdf" />
                <v-btn
                  color="error"
                  small
                  @click="eliminarFoto(img.url)"
                  top
                  right
                  absolute
                  fab
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
          <v-btn color="blue darken-1" class="elevation-6" dark @click="update">
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmacion" persistent max-width="600">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-h6"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong
                    >¿Estás seguro de que deseas aceptar este prospecto?</strong
                  >
                  <v-card-actions>
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="secondary"
                      text
                      large
                      class="mr-2"
                      @click="dialogConfirmacion = false"
                      >No, Cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="blue lighten-2"
                      dark
                      class="elevation-6"
                      large
                      @click="update()"
                      >Sí, Aceptar</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/contrato.jpg"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row justify="space-around">
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-subtitle-1"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el
                  ciclo?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="error"
                      text
                      class="mr-2"
                      @click="closeDelete()"
                      >No, cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="#5C5C5C"
                      dark
                      class="elevation-6"
                      @click="deleteItemConfirm()"
                      >Si</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRechazar" persistent max-width="800">
      <v-card style="position: relative">
        <v-card-title primary-title class="pb-0">
          Prospecto Rechazado
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="mensajeRechazo"
            outlined
            dense
            label="Escriba el motivo del rechazo..."
          >
          </v-textarea>
          <div class="text-right">
            <v-btn
              color="secondary"
              dark
              class="mr-2"
              @click="dialogRechazar = false"
              small
              tile
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="mr-2"
              @click="update()"
              small
              tile
            >
              Enviar
            </v-btn>
          </div>
        </v-card-text>
        <v-btn
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogRechazar = false"
          ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogImagen" max-width="900px" max-height="900px">
      <v-card class="elevation-0">
        <v-toolbar color="primary" dark>
          Examen Médico
          <v-spacer></v-spacer>
          <v-btn
            color="transparent elevation-0"
            x-small
            absolute
            top
            right
            fab
            class="mt-5 mr-0"
            @click="dialogImagen = false"
            ><v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
              lg="12"
              class="mt-2 text--center"
              align="center"
            >
              <v-card
                height="100%"
                class="elevation-0"
                align="center"
                outlined
                v-if="
                  idfoto.examen &&
                  idfoto.examen.substring(idfoto.examen.length - 3) !== 'pdf'
                "
              >
                <v-img
                  :src="url_servidor + idfoto.examen"
                  contain
                  aspect-ratio="2"
                  max-width="800"
                ></v-img>
              </v-card>

              <v-card v-else style="width: 100%; height: 800px">
                <embed
                  :src="url_servidor + idfoto.examen"
                  type="application/pdf"
                  style="width: 100%; height: 100%"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,
    plantel: null,
    planteles: [],
    puestos: [],
    medios: [],
    perfiles: [],
    trabajadores: [],
    examenes: [],
    formularios: [],
    usua: [],
    addcvfiles: [],
    addporfiles: [],
    addcvfiles2: [],
    addporfiles2: [],
    vistaPreviasCV: [],
    vistaPreviasPOR: [],
    fotosCV: [],
    fotosPOR: [],
    valor: 0,
    img: null,
    url_servidor: "",
    mensajeRechazo: "",
    idfoto: [],
    imagen: "",
    archivo: "",
    fullscreen: false,

    dialog_agregar: false,
    dialog_editar: false,
    dialogDelete: false,
    dialog: false,
    dialogConfirmacion: false,
    dialogRechazar: false,
    dialogImagen: false,
    dialogVer: false,

    editedIndex: -1,

    editedItem: {
      idexamenes_medicos: 0,
      idformulario: 0,
      comentario: "",
      examen: "",
      deleted: 0,
    },

    defaultItem: {
      idexamenes_medicos: 0,
      idformulario: 0,
      comentario: "",
      examen: "",
      deleted: 0,
    },

    cleanItem: {
      idexamenes_medicos: 0,
      idformulario: 0,
      comentario: "",
      examen: "",
      deleted: 0,
    },

    // Filtros:
    incio: null,
    fin: null,

    // tabla
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    horarios: [],
    headers: [
      { text: "ID", value: "idexamenes_medicos" },
      { text: "Nombre", value: "nombre_completo" },
      { text: "Comentarios", value: "comentario" },
      { text: "Examen", value: "examen" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
    formTitle() {
      return "Agregar Formulario";
    },
    formTitle2() {
      return "Editar Formulario";
    },
  },

  watch: {
    dialog_agregar(val) {
      val || this.close();
    },
    dialog_editar(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async created() {
    await this.initialize(), await this.getFormularios();

    this.url_servidor = axios.defaults.baseURL + "examenes_medicos/";
  },

  methods: {
    initialize() {
      this.cargar = true;
      this.examenes = [];
      return this.$http
        .get("examenes_medicos.get.all")
        .then((response) => {
          this.examenes = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    async save() {
      let fotosCV = [];
      if (this.vistaPreviasCV.length) {
        let respuesta = await this.subirFotosServidorCV();
        fotosCV = respuesta.data;
      }
      let nombreExamen = fotosCV.nombre;

      if (!nombreExamen) {
        return this.validarErrorDirecto("Favor de ingresar un Examen Médico");
      }

      let payload = {
        idformulario: this.defaultItem.idformulario,
        comentario: this.defaultItem.comentario,
        examen: nombreExamen,
      };

      this.$http
        .post("examenes_medicos.add", payload)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.initialize();
          this.vistaPreviasCV = [];
          this.$nextTick(() => {
            this.defaultItem = Object.assign({}, this.cleanItem);
            this.editedIndex = -1;
          });
          (this.vistaPreviasCV = []), (this.vistaPreviasPOR = []);
        })
        .catch((error) => {
          this.validarError(error);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
      this.close();
    },

    async update() {
      //Conseguimos la info para las fotos
      let fotosCV = [];

      if (this.vistaPreviasCV.length) {
        let respuesta = await this.subirFotosServidorCV();
        fotosCV = respuesta.data;
      }

      //aqui esta la info del Examen
      let nombreExamen = fotosCV.nombre;

      let payload = {
        id: this.editedItem.idexamenes_medicos,
        idformulario: this.editedItem.idformulario,
        comentario: this.editedItem.comentario,
        examen: this.editedItem.examen, //La informacion del examen esta oculta
        deleted: 0,
      };
      console.log(payload)
      //En caso de que se quiera actualizar el CV o el portafolio actualizamo el payload antes de enviarlo
      if (nombreExamen) {
        payload.examen = nombreExamen;
      }

      this.cargar = true;
      this.$http
        .put("examenes_medicos.update/" + payload.id, payload)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.initialize();
          this.addcvfiles = [];
          this.addporfiles = [];
          this.vistaPreviasCV = [];
          this.vistaPreviasPOR = "";
          this.dialogConfirmacion = false;
          this.dialogRechazar = false;
          this.mensajeRechazo = "";
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });

      this.close();
    },

    editItem(item) {
      this.editedIndex = this.horarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      console.log(this.editedItem);
      this.dialog_editar = true;
    },

    InfoConfirmacion(item) {
      this.editedIndex = this.horarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      this.editedIndex = this.horarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.cargar = true;
      // Lo mandapos por el EP
      this.editedItem["id_usuario"] = this.getdatosUsuario.iderp;
      this.$http
        .put("horarios.eliminar/" + this.editedItem.id_horario, this.editedItem)
        .then((response) => {
          this.cargar = true;
          this.closeDelete();
          this.initialize();
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    close() {
      this.dialog_editar = false;
      this.dialog_agregar = false;
      this.vistaPreviasCV = [],
      this.addcvfiles = [],
      this.$nextTick(() => {
        this.defaultItem = Object.assign({}, this.cleanItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDialog() {
      this.dialog = false;
    },

    //Angel
    cargarFotosCV() {
      if (this.addcvfiles.length < 1) {
        return;
      }

      this.addcvfiles.forEach((element, index) => {
        // creamos una variable tipo FormData
        let formData = new FormData();
        //se crea el objeto y se le agrega como un apendice el archivo
        formData.append("file", element);
        //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
        this.getBase64CV(element, formData);
      });
    },

    cargarFotosPOR() {
      if (this.addporfiles.length < 1) {
        return;
      }

      this.addporfiles.forEach((element, index) => {
        // creamos una variable tipo FormData
        let formData = new FormData();
        //se crea el objeto y se le agrega como un apendice el archivo
        formData.append("file", element);
        //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
        this.getBase64POR(element, formData);
      });
    },

    getBase64CV(file, formData) {
      var me = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const nombreSplit = file.name.split(".");
        const extensioArchivo = nombreSplit[nombreSplit.length - 1];
        me.vistaPreviasCV.push({
          url: reader.result,
          formData: formData,
          image_name: file.name,
          file,
          extensioArchivo,
        });
      };
    },

    getBase64POR(file, formData) {
      var me = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const nombreSplit = file.name.split(".");
        const extensioArchivo = nombreSplit[nombreSplit.length - 1];
        me.vistaPreviasPOR.push({
          url: reader.result,
          formData: formData,
          image_name: file.name,
          file,
          extensioArchivo,
        });
      };
    },

    eliminarFoto(value) {
      this.vistaPreviasCV.forEach((element, index) => {
        if (element.url == value) {
          this.vistaPreviasCV.splice(index, 1);
        }
      });
      this.vistaPreviasPOR.forEach((element, index) => {
        if (element.url == value) {
          this.vistaPreviasPOR.splice(index, 1);
        }
      });
    },

    eliminarfile(index, value) {
      this.addcvfiles = this.addcvfiles.filter((el) => {
        return el.name != value;
      });
      this.addporfiles = this.addporfiles.filter((el) => {
        return el.name != value;
      });
      this.addcvfiles2 = this.addcvfiles2.filter((el) => {
        return el.name != value;
      });
      this.addporfiles2 = this.addporfiles2.filter((el) => {
        return el.name != value;
      });
    },

    //Antes de subir las fotos las pasamos por aqui
    subirFotosServidorCV() {
      let formData = new FormData();

      for (const i in this.vistaPreviasCV) {
        formData.append("file", this.vistaPreviasCV[i].file);
      }

      return new Promise((resolve, reject) => {
        // obtener la extensión del archivo
        // Hacemos la petición
        this.$http
          .post(`examenes_medicos.add_archivo`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      });
    },

    subirFotosServidorPOR() {
      let formData = new FormData();

      for (const i in this.vistaPreviasPOR) {
        formData.append("file", this.vistaPreviasPOR[i].file);
      }

      return new Promise((resolve, reject) => {
        // obtener la extensión del archivo
        // Hacemos la petición
        this.$http
          .post(`formulario.add_archivo`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      });
    },

    getFormularios() {
      this.formularios = [];
      return this.$http
        .get("formulario.get")
        .then((response) => {
          this.formularios = response.data;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },
  },
};
</script>